
import { Options, Vue } from 'vue-class-component';
import ProductionTemplate from "@/components/ProductionTemplate.vue";
import ProductIntroduceData from "@/types/ProductIntroduceData";
@Options<Interpret>({
  components: {
    ProductionTemplate
  },
  props:{
  }
})
export default class Interpret extends Vue {
  introduce:ProductIntroduceData = {
    name:'秘塔AI翻译',
    profile:'专注在法律、公文类的机器翻译技术红圈所、外所、数百家企业法务部的一致选择',
    buttons:{
      experience:'https://fanyi.metaso.cn/#/',
      videoIntroduce:''
    },
    features:[
      {
        svgIconName: 'recommend',
        feature:{
          title:'算法专业',
          desc:[
            '用大量法律英语和官方数据训练算法',
            '秘塔自有算法，BLEU值大幅领先国内外各大翻译引擎',
          ]
        }
      },
      {
        svgIconName: 'lock',
        feature:{
          title:'数据安全',
          desc:[
            'RSA加密算法保护用户数据',
            '数据分散式、切片式存储',
            '用户可自行清除全部历史数据'
          ]
        }
      },
      {
        svgIconName: 'convenient',
        feature:{
          title:'使用便捷',
          desc:[
            '实时翻译：支持中英互译，10000+字/分钟',
            '翻译后的文件可以选择 单语 / 上中下英 / 上英下中 下载',
            '可自定义术语库',
          ]
        }
      },
      {
        svgIconName: 'satisfy',
        feature:{
          title: '体验感好',
          desc: [
            '原格式完全保留，无需担心格式再修改的问题',
            '术语篇章级统一',
            '支持多人在线协作、校阅',
            '修改留痕，方便回退',
          ]
        }
      }
    ],
    psText:'使用开通'
  }
}
